.grid-pagination {
  .ant-pagination {
    text-align: center;
    padding-bottom: 50px;
  }
}
.post-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  grid-gap: 30px;
  grid-auto-rows: 1fr;
  padding-bottom: 50px;
}

// img {
//   position: relative;
//   border-radius: 5px;
//   overflow: hidden !important;
//   object-fit: cover;
//   object-position: center center;
//   height: 100%;
//   width: 100%;
//   margin: 0 auto;
//   max-height: 350px;
//   z-index: 1;
// }

figure {
  height: 100%;
  max-height: 350px;
}
.field {
  display: flex;
  text-align: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  background-color: rgba(42, 142, 255, 0.12);
  p {
    color: rgb(0, 0, 0);
    font-family: "Josefin Sans", sans-serif;
    padding: 20px;
    font-size: 16px;
  }
}
.post-container {
  display: flex;
  padding: 5px;
  flex-direction: column;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px #203647;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);

  h2 {
    font-family: "Josefin Sans", sans-serif;
  }

  .author-text {
    font-family: "Source Sans Pro", sans-serif;
    color: #999;
  }

  a {
    padding: 0 5px;
  }

  .description-text {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 18px;
    font-weight: 400px;
    color: #555;
  }
}
