.cal {
  padding: 30px 0;
  width: 90%;
}
.head {
  padding-bottom: 30px;
}
.hide {
  text-align: center;
}
@media (min-width: 400px) {
  .hide {
    visibility: hidden;
  }
}
.calendar {
  background-color: white;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0px 0px 5px #555;
  width: 100%;
}

@media (max-width: 500px) {
  .calendar {
    height: 30rem;
  }
}
