.navbar {
  display: flex;
  //justify-content: center;
  align-items: center;
  //position: fixed;
  //height: 4rem;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 10px grey;
}

.navbar__navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  width: 100%;
  height: 100%;
}

.rightt {
  display: flex;
  align-items: center;
}

.donate-nav {
  display: flex;
  //padding-top: 2rem;
  align-items: center;
  .submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6rem;
    height: 3rem;
    padding: 1rem;
    border-color: #004e7c;
    border-radius: 0.5rem;
    //font-size: 2rem;
    background-color: rgba(255, 255, 0, 0);
    color: #004e7c;
    transition: 0.5s;
    #paypal {
      margin-left: 0.4rem;
    }
  }
  .submit:hover {
    transform: scale(1.05, 1.05);
    background-color: white;
    color: #004e7c;
    border-color: #004e7c;
  }
}

.navbar__logo {
  //width: 100%;
  //height: 100%;
  .logo {
    //width: 100%;
    //height: 100%;
    // width: 5rem;
    // height: 4rem;
    // margin-left: 3rem;
    // color: black;
    // opacity: 0.5;
    // text-decoration: none;
    // font-size: 1.5rem;
    img {
      width: 10rem;
      height: 100%;
    }
  }
}

.drawer-toggle {
  display: flex;
  margin-left: 50px;
}

.navbar_navigation-items {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  width: 100%;
  .items {
    text-decoration: none;
    list-style: none;
    //margin-left: 3rem;
    font-size: large;
    padding-top: 0;
    padding-bottom: 0;
    padding: 1.5rem;
    display: flex;
    color: #444;
  }
  .items:hover,
  .items:active {
    color: #007cc7;
  }
}

@media (max-width: 980px) {
  .navbar_navigation-items {
    opacity: 0;
    transition: opacity 1s ease-out;
    //display: none;
  }
}
@media (max-width: 960px) {
  .drawer-toggle {
    margin-left: 30px;
  }
  .navbar_navigation-items {
    display: none;
  }
}
