.content {
  padding: 30px 0;
  h1 {
    padding-bottom: 30px;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  padding: 30px 0;
  .btn {
    padding: 2px 30px;
    color: #007cc7;
    text-decoration: none;
    /* background-color: #417cef; */
    border: 0;
    font-size: 18px;
    border-radius: 12px;
    font-family: Oxygen, sans-serif;
    background-color: white;
    //smargin-top: 3rem;
    //margin-bottom: 2rem;
    border: 2px solid #555;
    transition: background-color 0.3s ease-out;
    transition: 0.4s;
    cursor: pointer;
  }
  .btn:hover,
  .btn:focus {
    background-color: #999;
    color: white;
    transform: scale(1.1, 1.1);
  }
}

.form {
  border-radius: 10px;
  box-shadow: 0px 0px 5px #555;
  padding: 30px;
}
