$grey_theme: #999;
$dark_theme: #555;
$blue_theme: #c8e4f1;

.form {
  background-color: white;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .b-con {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .btns {
      width: 20%;
      //padding: 2px 30px;
      /* background-color: #417cef; */
      border: 0;
      font-size: 18px;
      border-radius: 12px;
      font-family: Oxygen, sans-serif;
      background-color: rgba(15, 15, 15, 0.03);
      margin-top: 3rem;
      margin-bottom: 2rem;
      border: 2px solid $dark_theme;
      transition: background-color 0.3s ease-out;
      cursor: pointer;
    }
    .btns:hover,
    .login-btn:focus {
      background-color: $grey_theme;
      color: white;
    }
  }
}
