.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  width: 100%;
}

.home-p {
  padding-top: 30px;
}

.frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  width: 100%;
  h3 {
    padding: 30px 0;
    color: #555;
  }
}
.podcast {
  box-shadow: 0px 0px 5px #999;
  border: none;
  border-radius: 5px;
}
@media (max-width: 800px) {
  .podcast {
    width: 80%;
  }
}

.xmass {
  width: 40%;
  height: 50%;
  border-radius: 5px;
}
.xmass:hover {
  transform: scale(1.05);
  transition: 0.5s;
}

h3 {
  text-align: center;
}

.sub {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1200px;
  .text {
    justify-content: center;
    padding: 30px 0;
    width: 90%;
  }
  img {
    width: 60%;
    height: 50%;
  }
}

.call {
  width: 40%;
  height: 50%;
}

@media (max-width: 1200px) {
  img {
    height: 50%;
  }
  .call {
    width: 80%;
  height: 50%;
  }
  .xmass {
    height: 10%;
    width: 5%;
    margin-left: 0px !important;
  }
  .sub {
    flex-direction: column-reverse;
  }
}

.title {
  display: flex;
  justify-content: center;
}

.heading {
  margin-top: 4rem;
  text-align: center;
  font-weight: bold;
  font-family: "Josefin Sans", sans-serif;
  padding-bottom: 10px;
  width: 50%;
  color: #555;
  border-bottom: solid thick #999;
}
