$grey_theme: #999;
$dark_theme: #555;
$blue_theme: #c8e4f1;

.ui-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 4rem;
}

.box-controller {
  visibility: visible;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 24em;
  min-width: 21em;
  height: 35px;
  -webkit-box-shadow: 0px 0px 20px 0px $grey_theme;
  box-shadow: 0px 0px 20px 0px $grey_theme;
  margin-bottom: 3rem;
  align-items: center;
  transition: visibility 0.5s ease-out;
}

.controller {
  flex: 1;
  //margin-bottom: 3rem;
  text-align: center;
  background-color: white;
  height: 100%;
  line-height: 2;
  cursor: pointer;
}

.selected-controller {
  transition: border 0.5s ease-out;
  font-weight: bold;
  border-bottom: 2px solid $blue_theme;
}

.box-container {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
  background-color: white;
  max-width: 24em;
  min-width: 21em;
  box-shadow: 0px 0px 20px 0px $grey_theme;
  border-radius: 6px;
  padding: 24px;
}

.inner-container {
  height: 100%;
  transition: visibility 0.2s ease-out;
}

// .inner-container.show {
//   visibility: visible;
// }

//Header
.header {
  text-align: center;
  padding: 5px;
  margin-top: 4rem;
  margin-bottom: 4rem;
  /* margin-left: 35px; */
  /* margin-right: 35px; */
  font-family: "Josefin Sans", sans-seriff;
  //font-size: 2rem;
  font-weight: bold;
  border-bottom: 2px dotted $dark_theme;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.l-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  margin: 0;
}

.form-set {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  margin: 0;

  input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 2.1rem;
    border-radius: 3px;
    border: 1px solid $blue_theme;
    transition: border 0.4s ease-out;
    padding: 0.5rem;
    margin-bottom: 1rem;
    font-family: Oxygen, sans-serif;
    font-size: 12px;
    color: #3e3e42;
    background: rgba(15, 15, 15, 0.01);
  }

  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .l-con {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .btns {
        padding: 2px 30px;
        /* background-color: #417cef; */
        border: 0;
        font-size: 18px;
        border-radius: 12px;
        font-family: Oxygen, sans-serif;
        background-color: rgba(15, 15, 15, 0.03);
        margin-top: 3rem;
        margin-bottom: 2rem;
        border: 2px solid $dark_theme;
        transition: background-color 0.3s ease-out;
        cursor: pointer;
      }
      .btns:hover,
      .login-btn:focus {
        background-color: $grey_theme;
        color: white;
      }
    }
  }
}
