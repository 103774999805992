.masonry-post {
  position: relative;
  border-radius: 5px;
  overflow: hidden !important;
  background-size: cover;
  background-position: center center;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  min-height: 225px;

  .image-text {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    padding: 20px;
  }

  .image-title,
  .image-date,
  .tags-container,
  div {
    float: left;
    width: 100%;
    z-index: 10;
    font-weight: 300;
  }

  .image-title {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 20px;
    margin-block-start: 0;
    margin-block-end: 10px;
    color: rgb(242, 242, 247);
  }

  .image-date {
    font-size: 18px;
    color: #999;
  }
}

@media screen and (max-width: 900px) {
  .masonry-post {
    width: 100%;
    margin: 10px 0;
    height: 300px;
  }
}
